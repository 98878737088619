<template>
    <div class="access">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <img src="@/assets/images/home/access.svg" class="img-fluid" alt="Tendencys" loading="lazy" />
                </div>
                <div class="col-md-6">
                    <div class="access__content">
                        <div>
                            <h2 class="access__title">
                                {{ $t('Access multiple applications with a few clicks') }}
                            </h2>
                            <p class="access__text">
                                {{ $t('Have access to different sites just by entering your password.') }}
                            </p>
                            <ui-button to="/signup" variant="primary">
                                {{ $t('Signup') }}
                            </ui-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import UiButton from '@/components/ui/buttons/Button.vue';

export default {
    name: 'access',
    components: { UiButton },
};
</script>

<style lang="scss">
.access {
    padding-top: 100px;
    padding-bottom: 100px;

    @media(max-width: 768px) {
        padding-top: 70px;
        padding-bottom: 70px;
    }

    &__content {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media(max-width: 768px) {
            text-align: center;
        }
    }

    &__title {
        font-weight: 600;
        font-size: 35px;
        color: #202225;

        @media(max-width: 768px) {
            font-size: 28px;
        }
    }

    &__text {
        font-weight: 500;
        font-size: 16px;
        color: #787F84;
    }
}
</style>
